/* html {
  margin-right: calc(-1 * (100vw - 100%));
} */

.demo-editor {
  min-height: 200px;
}

.price {
  font-size: 25px;
}

.text-badge {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  height: 24px;
  font-size: 12px;
  margin-top: 10px;
}

.price {
  font-size: 25px;
}

.text-badge {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  height: 24px;
  font-size: 12px;
  margin-top: 10px;
}

.success-badge {
  /* background-color: #d4f1d8; */
  background-color: #166ec6;
}

.danger-badge {
  background-color: #e24b43;
  color: #ffffff;
}

.warning-badge {
  background-color: #eedd86;
}

.silver-badge {
  background-color: #919eab40;
}

.angle-background-color {
  background-color: #f2f4f7;
}

.hc {
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
}

.vc {
  display: flex !important;
  align-items: center !important;
  margin: auto !important;
}

.cur-pointer {
  cursor: pointer !important;
}
